export default {
  data () {
    return {
      orderNo: '',
      fromStationCode: '',
      toStationCode: '',
      time: ''
    }
  },
  components: {},
  created () {
    this.orderNo = this.$route.query.orderNo
    this.fromStationCode = this.$route.query.fromStationCode
    this.toStationCode = this.$route.query.toStationCode
    this.time = this.$route.query.time
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    checkDetail() {
      this.$router.push({
        name: 'distributor-train-front-train-order-payment',
        query: {
          orderNo: this.orderNo
        }
      })
    },
    back() {
      this.$router.push({
        name: 'distributor-train-front-search',
        query: {
          fromStationCode: this.toStationCode,
          toStationCode: this.fromStationCode,
          time: this.time
        }
      })
    }
  }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
